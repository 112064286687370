import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';

@Component({
  selector: 'app-apikey-page',
  templateUrl: './apikey-page.component.html',
  styleUrls: ['./apikey-page.component.css']
})
export class ApikeyPageComponent implements OnInit {
  apiKey;
  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.apiKey = this.apiService.apiKey;
  }

}
