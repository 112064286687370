import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from '../api.service';
@Component({
  selector: 'app-success-page',
  templateUrl: './success-page.component.html',
  styleUrls: ['./success-page.component.css']
})
export class SuccessPageComponent implements OnInit {
  websiteLink = '';
  websiteDashLink = this.websiteLink + '/wp-admin';
  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.websiteLink = this.apiService.finalLink ;
    this.websiteDashLink = this.websiteLink + '/wp-admin';
  }
  public navigateToSection(event) {
   // event.preventDefault();
  }

}
