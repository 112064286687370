import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-second-step',
  templateUrl: './second-step.component.html',
  styleUrls: ['./second-step.component.css']
})
export class SecondStepComponent implements OnInit {
  model = {
    businessName: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
    terms: false,
  };
  submitted = false;

  constructor() { }

  ngOnInit() {
  }
  onSubmit() { this.submitted = true; }
  get diagnostic() { return JSON.stringify(this.model); }
}
