import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ModalService } from '../_helpers/modal/modal.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  firstChoice       = 0;
  choosesDomainValid     = false;
  showFormDetails     = false;
  domainCheckResult = '';
  domainCheckColor  = '#434bdf';
  checkRequest;
  headerImgToUpload: File = null;
  logoImgToUpload: File = null;
  model = {
    domain: '',
    fullName: '',
    businessName: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
    industry: '',
    saleAgent: '',
    saleAgentName: '',
    saleAgentEmail: '',
    terms: false,
    headerImg: '',
    logoImg: ''
  };
  msgFormModel = {
    form: 'form3',
    currentwebsite: '',
    businessName: '',
    phone: '',
    email: '',
    message: ''
  };
  buildFormModel = {
    form: 'form2',
    currentwebsite: '',
    username: '',
    password: '',
    businessName: '',
    phone: '',
    email: '',
    message: ''
  };
  submitted = false;
  errorBodyText: string;
  successBodyText: string;
  apiKey;
  showAnnouncements = true;
  constructor(private apiService: ApiService, private modalService: ModalService, private router: Router) { }

  ngOnInit() {
    this.apiKey = this.apiService.apiKey;
    this.createAnnouncementsSection();
  }

  createAnnouncementsSection() {
    // @ts-ignore
    if (window.hbspt) {
      // @ts-ignore
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '7182906',
        formId: '244adc1f-cb61-4bbe-9fd2-b7bab329cebe',
        target: '#announcementsSection'
      });
    }
  }

  selectFirstChoice(choice) {
    this.firstChoice = choice;
  }

  checkDomainName(domain): void {
    this.domainCheckResult = 'Checking your domain name...';
    this.domainCheckColor  = '#434bdf';
    this.choosesDomainValid     = false;
   // this.model.domain = '';
    const term = domain.trim();
    // basic test, size,reserved words
    if (term.length < 4) {
      this.choosesDomainValid = false;
      this.domainCheckResult = 'Must be at least 4 characters, letters and numbers only.';
      this.domainCheckColor  = 'red';
      this.choosesDomainValid     = false;
     // this.model.domain = '';
      return;
    }
    if (!/^[a-z0-9]+$/i.test(term)) {
      this.choosesDomainValid = false;
      this.domainCheckResult = 'Must be letters and numbers only.';
      this.domainCheckColor  = 'red';
      this.choosesDomainValid     = false;
     // this.model.domain = '';
      return;
    }
    if (this.checkRequest) {
      this.checkRequest.unsubscribe();
    }
    const body = term ? { username: term } : {};
    this.checkRequest  = this.apiService.checkDomain(body).subscribe( (res: any) => {
    this.domainCheckResult = res.status;
    if ( res.status === 'Available') {
      this.domainCheckColor  = 'green';
      this.domainCheckResult = 'Congratulation, this domain name  is available';
      this.choosesDomainValid = true;
     // this.model.domain = domain;
      this.showFormDetails = true;
    } else {
      this.domainCheckColor  = 'red';
      this.domainCheckResult = 'Unfortunately, this domain name  isn\'t  available';
      this.choosesDomainValid = false;
     // this.model.domain = '';
    }
  }, (error) => {
    console.log(error);
  });
  }
  submitForm() {
    console.log('submit the form');
  }
  onSubmit() {
    this.submitted = true;
    this.showLoader();
    if ( this.logoImgToUpload ) {
      this.apiService.postFile(this.logoImgToUpload).subscribe(response => {
        // @ts-ignore
        this.model.logoImg = response.url;
        this.uploadHeaderImage();
      }, error1 => {
        this.errorBodyText = error1.message;
        this.modalService.open('app-modal-error1');
        this.hideLoader();
      });
    } else {
      this.uploadHeaderImage();
    }
  }
   uploadHeaderImage() {
     if ( this.headerImgToUpload ) {
       this.apiService.postFile(this.headerImgToUpload).subscribe(response => {
         // @ts-ignore
         this.model.headerImg = response.url;
         this.createSubdomain();
       }, error1 => {
         console.log(error1);
         this.errorBodyText = error1;
         this.modalService.open('app-modal-error1');
         this.hideLoader();
       });
     } else {
       this.createSubdomain();
     }
   }
   createSubdomain() {

    this.apiService.signUp(this.model).subscribe( (res: any) => {
      if ( res.status === 'error') {
        this.errorBodyText = res.message;
        this.modalService.open('app-modal-error1');
      } else {
        this.model.domain = res.link;
        this.apiService.signUpCompany(this.model).subscribe();
        this.apiService.finalLink = res.link;
        this.successBodyText = res.message;
        this.router.navigateByUrl('success');
      }
      this.hideLoader();
    }, (error) => {
      this.errorBodyText = error;
      this.modalService.open('app-modal-error1');
      this.hideLoader();
    });
  }
  onSubmitMsgForm() {
    this.showLoader();
    this.apiService.sendMessage(this.msgFormModel).subscribe( (res: any) => {
      if ( res.status === 'error') {
        this.errorBodyText = res.message;
        this.modalService.open('app-modal-error1');
      } else {
        this.router.navigateByUrl('build-me-website');
      }
      this.hideLoader();
    }, (error) => {
      this.errorBodyText = error;
      this.modalService.open('app-modal-error1');
      this.hideLoader();
    });
  }
  onSubmitBuildForm() {
    this.showLoader();
    this.apiService.sendMessage(this.buildFormModel).subscribe( (res: any) => {
      if ( res.status === 'error') {
        this.errorBodyText = res.message;
        this.modalService.open('app-modal-error1');
      } else {
        this.router.navigateByUrl('sent');
      }
      this.hideLoader();
    }, (error) => {
      this.errorBodyText = error;
      this.modalService.open('app-modal-error1');
      this.hideLoader();
    });
  }
  get diagnostic() { return JSON.stringify(this.model); }
  hideLoader() {
    document.getElementById('loader_form').style.display = 'none';
  }

  showLoader() {
    document.getElementById('loader_form').style.display = 'block';
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
  clickOnHelp(event) {
    event.preventDefault();
  }
  handleFileInput(event, Input) {
    const maxSize = 8000000;
    const files: FileList = event.target.files;
    const extensions = [
      'image/jpeg',
      'image/jpg',
      'image/gif',
      'image/png',
    ];
    if ( files.item(0)) {
      if ( files.item(0).size > maxSize ) {
        this.errorBodyText = 'Image too big, please choose an other image';
        this.modalService.open('app-modal-error1');
        event.preventDefault();
        event.stopPropagation();
        // @ts-ignore
        document.getElementById('headerImg').value = '';
      } else {
        if (extensions.indexOf(files.item(0).type) === -1) {
          this.errorBodyText = 'File type not authorized, we accept only  gif, jpg, jpeg, and png';
          this.modalService.open('app-modal-error1');
          event.preventDefault();
          event.stopPropagation();
          if (Input === 'logo') {
            // @ts-ignore
            document.getElementById('logoImg').value = '';

          } else {
            // @ts-ignore
            document.getElementById('headerImg').value = '';
          }
        } else {
          if (Input === 'logo') {
            this.logoImgToUpload = files.item(0);

          } else {
            this.headerImgToUpload = files.item(0);
          }
        }

      }
    }
  }
  clickOnNext(event) {
    if ( this.firstChoice === 2) {
      this.router.navigateByUrl('key');
    }
    if ( this.firstChoice === 3) {
      this.router.navigateByUrl('build-me-website');
    }
    if ( this.firstChoice === 1 || this.firstChoice === 4 ) {
      this.showAnnouncements = false;
    }
  }
  clickOnPrev(event) {
    this.showAnnouncements = true;
    this.createAnnouncementsSection();
  }
}
