import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FormComponent } from './form/form.component';
import { HttpClientModule } from '@angular/common/http';
import { ApiService } from './api.service';
import { FormsModule } from '@angular/forms';
import { SecondStepComponent } from './second-step/second-step.component';
import { MustMatchDirective } from './_helpers/must-match.directive';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ModalComponent } from './_helpers/modal/modal.component';
import { SuccessPageComponent } from './success-page/success-page.component';
import { SuccessSendingComponent } from './success-sending/success-sending.component';
import {ExternalUrlService} from './external-url.service';
import { ApikeyPageComponent } from './apikey-page/apikey-page.component';

const appRoutes: Routes = [
  { path: 'setup/:id',      component: HomeComponent },
  { path: 'success',      component: SuccessPageComponent },
  { path: 'oldsent',      component: SuccessSendingComponent },
  { path: 'key',      component: ApikeyPageComponent },
  {
    path: '',
    canActivate: [ExternalUrlService],
    component: ExternalUrlService,
    data: {
      externalUrl: 'https://www.clover.com/oauth/authorize?client_id=6MWGRRXJD5HMW'
    }
  },
  {
    path: 'sent',
    canActivate: [ExternalUrlService],
    component: ExternalUrlService,
    data: {
      externalUrl: 'https://smartonlineorder.com/meeting/'
    }
  },
  {
    path: 'build-me-website',
    canActivate: [ExternalUrlService],
    component: ExternalUrlService,
    data: {
      externalUrl: 'https://bmw.smartonlineorder.com'
    }
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },
];


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FormComponent,
    SecondStepComponent,
    MustMatchDirective,
    PageNotFoundComponent,
    ModalComponent,
    SuccessPageComponent,
    SuccessSendingComponent,
    ApikeyPageComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false,
        anchorScrolling: 'enabled'
      }
    ),
    // other imports here
    BrowserModule,
    HttpClientModule,
    FormsModule,
    RouterModule
  ],
  providers: [ApiService, ExternalUrlService],
  bootstrap: [AppComponent]
})
export class AppModule { }
