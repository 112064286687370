import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {allowNewBindingsForStylingContext} from '@angular/core/src/render3/styling/class_and_style_bindings';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiLink = 'https://api-v2.smartonlineorders.com/v2/';
  apiCheckingLink = 'https://smartonlineorder.com/wp-json/wp/v2/users/check';
  controllerLink = 'https://welcome.smartonlineorder.com/api/';
  apiKey = null;
  private _finallink = '';
  constructor(private http: HttpClient) { }
  checkDomain(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Accept:  'application/json'
      })
    };
    return this.http.post(this.apiCheckingLink, body, httpOptions)
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }
  signUp(body) {
    body.api_key = this.apiKey;
    return this.http.post(this.controllerLink + 'setup', body)
      .pipe(
       // retry(3),
        catchError(this.handleError)
      );
  }
  signUpCompany(body) {
    body.api_key = this.apiKey;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'X-Authorization':  this.apiKey
      })
    };
    return this.http.post(this.apiLink + 'hupspot/company', body, httpOptions);
  }
  sendMessage(body) {
    body.api_key = this.apiKey;
    return this.http.post(this.controllerLink + 'send_message', body)
      .pipe(
       // retry(3),
        catchError(this.handleError)
      );
  }
  postFile(fileToUpload: File) {
    const endpoint = this.controllerLink + 'upload_file';
    const headers = new HttpHeaders({
      'X-Authorization': this.apiKey
    }
    );
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(endpoint, formData, { headers })
      .pipe(
        // retry(3),
        catchError(this.handleError)
      );
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      if (error.error.message){
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
        return throwError(error.error.message);
      }
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error( `Backend returned code ${error.status}, ` + `body was: ${error.error}`);
      console.error( `body was:`);
      console.error( error );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  };

  setApiKey(key) {
    // localStorage.setItem('apiKey', key);
    this.apiKey = key;
  }

  get finalLink(): string {
    return this._finallink;
  }

  set finalLink(value: string) {
    this._finallink = value;
  }
}
