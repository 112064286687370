import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../api.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(private route: ActivatedRoute, private apiService: ApiService) {
  }

  ngOnInit() {
  //  console.log(this.route.snapshot.paramMap);
    this.route.paramMap.subscribe(params => {
      // (+) before `params.get()` turns the string into a number
      const key = params.get('id');
      this.apiService.setApiKey(key);
    });
  }

}
